import * as React from 'react'
import NavHeader from '../../components/navHeaderEn'
import SiteFooter from '../../components/footerEn'
import PageTitle from '../../components/pageTitle'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import * as aboutStyle from '../../styles/about.module.scss'
import { graphql } from 'gatsby'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const About = ({data}) => {
  return (
    <main>
        <NavHeader/>
        <PageTitle pageTitle="About us"/>
            <div className="mainContainer">
                <div>
                        <section>
                        <div className="sectionContainer">
                            <h1>Introduction</h1>
                            <p>
                            Since the year 2004 GlobalTel, JSC is a significant company with the specialization on information systems and a communication infrastructure for the initiation and development of eGovernment services in the Slovak Republic. 

                            </p>
                            <p>
                            Our main projects include the supply of modules and services for the backbone infrastructure of integrated information system of the state - <strong>Central portal of public administration (CPPA)</strong>.
                            </p>
                        </div>
                        <div className="section-bbl-sp-item" id="positive"></div>
                        <div className="section-ln-sp-item"></div>
                        </section>
                        <section>
                        <h1>Our Aim</h1>
                        <div className={aboutStyle.goalContainer}>
                            <div>
                            <p>
                            By implementing solutions within eGovernment we are helping to quickly and efficiently innovate the IT architecture as well as the processes of the public administration with the focus on the use of state services both by the citizens and entrepreneurs.
                            </p>
                            <p>
                            We bring innovative solutions implemented within an information-communication infrastructure (ICT). We interconnect a modern architecture with technically accurate know-how and well-established technologies.
                            </p>
                            </div>
                            <div className={aboutStyle.goalImage}>
                            <StaticImage alt="Company illustration" src="../../images/skyline.svg"/>
                            </div>
                        </div>
                        <div className="section-bbl-sp-item" id="negative"></div>
                        <div className="section-ln-sp-item"></div>
                        </section>
                        <section id="certificates">
                        <h1>Certificates</h1>
                        <p>The company has put into practice international certificates of the Quality, IT and Application Security
                        Management System.</p>
                        <div>
                        <SimpleReactLightbox>
                        <SRLWrapper>
                        <div className={aboutStyle.certificatesContainer}>
                            {data.gallery.edges.map(({node}) =>(
                                <div key={node.id} className={aboutStyle.certificate}>
                                <a href={node.publicURL}>
                                <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('_').join(' ').split('.')[0]} />
                                </a>
                                <p>{node.base.split('_').join(' ').split('.')[0]}</p>
                                </div>
                            ))}
                        </div>
                        </SRLWrapper>
                        </SimpleReactLightbox>
                        </div>
                        </section>
                    </div>
                </div>
        <SiteFooter/>
    </main>
  )
}

export const query = graphql`
   query {
    gallery: allFile(filter: {relativeDirectory: {eq: "cert_en"}}) {
        edges {
          node {
            id
            base
            name
            publicURL
            childImageSharp {
              gatsbyImageData(
                height: 420
                width: 277
                transformOptions: {fit: COVER}
                placeholder: BLURRED
                webpOptions: {quality: 10}
              )
            }
          }
        }
      }
  }
`

export default About